<template>
  <div>
    <!-- 차트 -->
    <c-card title="차트" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-sm-12 col-md-8 col-lg-8">
          <apexchart 
            ref="chart1" 
            height="250" 
            type="bar"
            :width="chart1.chartWidth"
            :options="chart1.chartOptions" 
            :series="chart1.series"></apexchart>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
          <apexchart 
            ref="chart2" 
            height="250" 
            type="bar" 
            :width="chart2.chartWidth"
            :options="chart2.chartOptions" 
            :series="chart2.series"></apexchart>
        </div>
      </template>
    </c-card>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <c-table
          ref="table"
          :title="$language('개선 목록')+' ['+$language(tab.label)+']'"
          tableId="kras01"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <template v-slot:customArea="{ props }">
            <template>
              <span>
                {{props.row.actionCompleteRequestDate}}
                <q-badge v-if="props.row.completeDateOver > 0" color="negative" :label="$language('지연')" class="blinking" />
              </span>
            </template>
          </template>
        </c-table>
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr-action-rate-detail',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        tab: 'task',
        clickInfo: '',
        data: [],
        searchParam: {
          plantCd: null,
          actionCompleteRequestDates: [],
          actionDeptCd: '',
          actionVendorCd: '',
          actionFlag: 'D',
        }
      }),
    },
  },
  data() {
    return {
      chart1: {
        chartOptions: {
          title: {
            text: '개선처리'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: "건 수",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      chart2: {
        chartOptions: {
          title: {
            text: '즉시처리'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: "건 수",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      curTab: 'IS00000001',
      tab: 'IS00000001',
      tabItems: [],
      tabParam: {
        search: '',
      },
      grid: {
        columns: [],
        data: [],
        allData: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.chart1.series.name = this.$language('건 수')
      this.chart2.series.name = this.$language('건 수')
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.status.list.url
      // code setting
      // list setting
      this.setChart();
      this.getList();
    },
    setChart() {
      this.chart1.chartOptions.title.text = this.$language('개선처리')+' ['+this.popupParam.clickInfo+']'
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.chart1.chartOptions.xaxis.categories = this.$_.map(_result, 'stepperMstNm')
        this.$_.forEach(_result, item => {
          this.tabItems.push({
            name: item.stepperMstCd,
            label: item.stepperMstNm,
            icon: item.iconClass
          })
        })
        this.tabItems.push({ name: 'immTotal', icon: 'task_alt', label: this.$language('즉시처리')})
      });
      this.chart2.chartOptions.title.text = this.$language('즉시처리')+' ['+this.popupParam.clickInfo+']'
      this.chart2.chartOptions.xaxis.categories = [this.$language('즉시처리건')]
      this.chart1.series[0].data = [
        this.popupParam.data.requesting,
        this.popupParam.data.receipting,
        this.popupParam.data.actioning,
        this.popupParam.data.confirming,
        this.popupParam.data.complete,
      ]
      this.chart2.series[0].data = [
        this.popupParam.data.immTotal,
        // this.popupParam.data.immComplete,
      ]
      setTimeout(() => {
        this.chart1.chartWidth = '100%';
        this.chart2.chartWidth = '100%';
      }, 200);
      this.$refs['chart1'].refresh();
      this.$refs['chart2'].refresh();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.popupParam.searchParam;
      this.$http.request((_result) => {
        this.grid.allData = _result.data;
        this.tabClick({ name: this.tab });
      },);
    },
    tabClick(tab) {
      this.curTab = tab.name;
      if (tab.name !== 'immTotal') {
        // 개선처리건일 경우
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청정보',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'actionName',
            field: 'actionName',
            label: '조치부서/업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionUserNames',
            field: 'actionUserNames',
            label: '조치자',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
        ];
        // 개선완료인 경우
        if (tab.name === 'IS00000020') {
          this.grid.columns = this.$_.concat(this.grid.columns, [
            {
              name: 'suppleFlagName',
              field: 'suppleFlagName',
              label: '적합/보완',
              align: 'center',
              style: 'width:80px',
              sortable: true
            },
            {
              name: 'ibmSuppleStepName',
              field: 'ibmSuppleStepName',
              label: '보완진행단계',
              align: 'center',
              style: 'width:100px',
              sortable: true
            },
            {
              name: 'suppleRequest',
              field: 'suppleRequest',
              label: '보완요청 내용',
              align: 'left',
              style: 'width:200px',
              sortable: true,
            },
            {
              name: 'suppleScheduleDate',
              field: 'suppleScheduleDate',
              label: '보완완료 요청일',
              align: 'center',
              style: 'width:170px',
              sortable: true,
            },
            {
              name: 'suppleCompleteDate',
              field: 'suppleCompleteDate',
              label: '보완완료일',
              align: 'center',
              style: 'width:90px',
              sortable: true,
            },
          ])
        }
        this.grid.data = this.$_.filter(this.grid.allData, { ibmStepCd: tab.name, ibmClassCd: 'IC00000001' })
      } else {
        // 즉시처리건일 경우
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청정보',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ];
        this.grid.data = this.$_.filter(this.grid.allData, { ibmClassCd: 'IC00000005' })
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (this.curTab !== 'immTotal') {
        this.popupOptions.title = '개선 상세'; 
        this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시개선 상세'; 
        this.popupOptions.target = () => import(`${'./imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        isSearch: true,
        sopImprovementId: row.sopImprovementId,
        ibmTaskTypeCd: row.ibmTaskTypeCd,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
